/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "~@ng-select/ng-select/themes/default.theme.css";

@import '~ngx-ui-switch/ui-switch.component.scss';


// .ng-select .ng-dropdown-panel .ng-option.ng-option-marked{
//     background-color: rgb(255, 239, 239) !important;
//   }
//   .ng-select-multiple > .ng-has-value > .ng-value-container > .ng-value > .ng-value-label {
//     background-color: red ;
//     color: aliceblue;
//   }
//   .ng-select-multiple > .ng-has-value > .ng-value-container > .ng-value > .ng-value-icon{
//     background-color: rgb(255, 142, 142);
//   }
.ng-select.ng-select-single {
    .ng-dropdown-panel {
        font-size: 10px;
    }

    .ng-select-container {
        min-height: 30px !important;
        height: 30px !important;
        border-radius: 8px !important;
        --tw-border-opacity: 1 !important;
        vertical-align: auto !important;


        border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;

        .ng-value-container {
            --tw-text-opacity: 1 !important;
            color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
            font-size: 10px;

            .ng-placeholder {
                color: #97A7BC;
                font-size: 10px;
            }
        }
    }
}

.ng-select.ng-select-multiple {
    .ng-dropdown-panel {
        font-size: 10px;
    }

    .ng-select-container {
        min-height: 30px !important;
        border-radius: 8px !important;
        --tw-border-opacity: 1 !important;
        vertical-align: auto !important;


        border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;

        .ng-value-container {
            --tw-text-opacity: 1 !important;
            color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
            font-size: 10px;

            .ng-placeholder {
                color: #97A7BC;
                font-size: 10px;
            }
        }
    }
}

   
.ng-select .ng-select-container .ng-value-container .ng-input>input {
    min-height: 20px !important;
    height: 20px !important;

}





input {
    min-height: 30px !important;
    height: 30px !important;
    font-size: 10px !important;

}

input::placeholder {
    font-size: 10px !important;

}

label {
    font-size: 10px !important;
    margin-top: 4px !important;
}





//   .ng-select-container::placeholder{
//     color: red;
//   }
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
